<template>
    <dialog-component :open='true' @close='$emit("close")'>
        <template #title>
            <span v-if='mode !== "modify"'>{{ questionnaireId ? 'סיום שאלון' : /*x*/ 'הוספת שאלון' }}</span>
            <label v-else id='addQuestionnaireTitle'>
                <input
                    :class='{ error: name.error }'
                    v-model='name.value'
                    @input='name.error = false'
                    id='questionnaireName'
                    placeholder='שם השאלון'
                />
                <span class='material-icons-round'>edit</span>
            </label>
        </template>
        <form @submit.prevent='submit' id='addQuestionnaireForm' :class='{ editing: mode !== "new", terminating: mode === "terminate" }'>
            <div id='addQuestionnaireContent'>
                <input
                    v-if='mode === "new"'
                    :class='{ error: name.error }'
                    v-model='name.value'
                    @input='name.error = false'
                    id='questionnaireName'
                    placeholder='שם השאלון'
                />
                <template v-if='mode !== "terminate"'>
                    <div id='defineFrequency'>
                        <label>
                            תדירות מילוי
                            <custom-select v-model='frequency.value' :options='frequency.options' />
                        </label>
                        <label id='numberOfDays' v-if='frequency.value == -2'>
                            (פעם ב-<input v-model='frequency.days' /> ימים)
                        </label>
                    </div>
                    <label v-if='mode === "new"'>
                        תאריך התחלה
                        <date-picker v-model='startDate.value' @change='onStartDateChange' allowed='future' />
                    </label>
                    <label id='defineTimes'>
                        <div>{{ frequency.value != 0 ? 'בשעה' : 'בשעות' }} <i class='material-icons-round'>info</i></div>
                        <custom-select
                            v-for='(t, i) of times.value'
                            :key='t' v-model='times.value[i]'
                            :options='times.options'
                            :class='{ error: t === null && times.error }'
                            @change='onTimeChange'
                        />
                        <i v-if='frequency.value == 0' id='addTime' class='material-icons-outlined' @click='times.value.push(null)  '>
                            add_circle
                        </i>
                    </label>
                </template>
                <div v-if='mode !== "modify" && frequency.value != -1'>
                    <label id='endDateLabel'>
                        <div>תאריך סיום</div>
                        <switch-component v-if='mode === "new"' v-model='endDate.active' />
                    </label>
                    <date-picker v-if='endDate.active' v-model='endDate.value' :min='endDateMin' />
                    <div v-else id='noEndDate'>ללא תאריך סיום</div>
                </div>
            </div>
            <div id='addQuestionnaireButtons'>
                <button>שמירה</button>
                <button @click='$emit("close")'>ביטול</button>
            </div>
        </form>
    </dialog-component>
</template>

<script lang='ts'>
import { isEqual, isBefore } from 'date-fns';
import { getCommitId } from '../helpers';
import { dateToDashDelimited } from '@/helpers';
import { defineComponent, PropType } from '@vue/runtime-core';
import { addCommit, setQuestionnaire } from '@/SharedChart/Patient/module';
import { RawQuestionnaire } from '@/ts/interfaces/Questionnaire';

const data = () => ({
    name: { value: '', error: false },
    frequency: {
        value: 1,
        days: null,
        options: {
            1: `פעם ביום`,
            [-2]: `פחות מפעם ביום`,
            0: `יותר מפעם ביום`,
            [-1]: `חד פעמי`,
        }
    } as { value: number, days: number | null, options: any },
    times: {
        value: [-1],
        options: { [-1]: 'לא משנה', ...Object.fromEntries(new Array(24).fill(true).map((_, index) => [index, (index < 10 ? `0${index}` : index) + ':00'])) },
        error: false
    } as { value: (number | null)[], options: any, error: boolean },
    startDate: { value: new Date() },
    endDate: { active: false, value: new Date() },
});

export default defineComponent({
    beforeMount(){
        if(this.mode === 'new')
            return

        if(this.mode === 'terminate')
            this.endDate.active = true;

        const questionnaire = this.$store.state.patient!.questionnaires.find(q => q.id === this.questionnaireId);
        if(!questionnaire)
            return;

        const { times, name, end_date, start_date, frequency } = questionnaire;
        if(times.length)
            this.times.value = [...times];
        if(end_date)
            this.endDate = { active: true, value: end_date };

        this.name.value = name;
        this.startDate.value = start_date;

        this.frequency.value = (() => {
            if(frequency > 1){
                this.frequency.days = frequency;
                return -2;
            }

            if(times.length > 1)
                return 0;

            return !end_date || !isEqual(start_date, end_date) ? 1 : -1;
        })();
    },
    data,
    props: {
        questionnaireId: String,
        mode: { type: String as PropType<'new' | 'modify' | 'terminate'> }
    },
    emits: ['close'],
    watch: {
        frequency: {
            handler({ value }){
                const firstTime = this.times.value[0] as number;
                if(value == 0 && this.times.value.length === 1)
                    this.times.value = [firstTime >= 0 ? firstTime : null, null];
                else if(value != 0 && this.times.value.length > 1)
                    this.times.value = [firstTime !== null ? firstTime : -1];
            },
            deep: true
        }
    },
    computed: {
        endDateMin(){
            return dateToDashDelimited(this.startDate.value as Date);
        }
    },
    methods: {
        onStartDateChange(){
            if(isBefore(this.endDate.value as Date, this.startDate.value as Date))
                this.endDate.value = this.startDate.value;
        },
        onTimeChange(){
            if(!this.times.value.includes(null))
                this.times.error = false;
        },
        validate(){
            const { name, frequency, times } = this;
            let hasErrors = false;
            if(!name.value.length)
                hasErrors = name.error = true;
            if(frequency.value == 0 && times.value.includes(null))
                hasErrors = times.error = true;

            return !hasErrors;
        },
        async submit(){
            if(!this.validate())
                return;

            try{
                const target = `/questionnaire${this.mode !== 'new' ? `/${this.questionnaireId}` : ''}`
                const newQuestionnaire = await this.$request(target, 'put', this.getPayload());
                if(this.mode !== 'new') this.$emit('close');
                else this.$router.push(`/${this.$route.params.patientId}/questionnaires/questions/${newQuestionnaire.body.id}`);
                
                setQuestionnaire(newQuestionnaire.body);
                addCommit(newQuestionnaire.body.commit_id);
            }catch(error){
                console.error(error)
            }
        },
        getPayload(){
            const { name, frequency, times, mode } = this;

            const startDate = dateToDashDelimited(this.startDate.value as Date);
            const endDate = dateToDashDelimited(this.endDate.value as Date);
            
            const payload = {} as RawQuestionnaire;
            if(mode !== 'terminate')
                Object.assign(payload, {
                    name: name.value,
                    frequency: frequency.value == -2 ? frequency.days : 1,
                    times: frequency.value == 0 || ![null, -1].includes(times.value[0]) ? times.value : []
                })
             else
                Object.assign(payload, { end_date: endDate })

            if(mode === 'new')
                Object.assign(payload, {
                    end_date: this.endDate.active ? endDate : (frequency.value === -1 ? startDate : null),
                    start_date: startDate
                })
            
            if(this.questionnaireId){
                const commitId = getCommitId(this.questionnaireId);
                if(commitId)
                    payload.commit_id = commitId;
            }

            return payload;
        }
    }
});
</script>

<style lang='scss'>
#addQuestionnaireTitle {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #333;
    margin: 0;
    width: 100%;

    & #questionnaireName {
        font-size: 1.1rem;
        background-color: transparent;
        border: none;
        box-shadow: unset;
        flex: 1;

        &::placeholder {
            color: #aaa;
        }
    }
}

#addQuestionnaireForm {
    background-color: transparent;

    &.editing {
        width: 28rem;

        & #addQuestionnaireContent {
            display: flex;
            flex-direction: column;
        }
    }

    &.terminating {
        width: 18rem;

        & #addQuestionnaireContent {
            padding: 1.5rem 0 2rem;
            align-items: center;
        }

        & button {
            padding: 0.8rem !important;
        }
    }

    & #addQuestionnaireContent {
        // width: 50rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2.5rem 7rem;
        padding: 1rem 3rem 3rem;
        overflow-y: auto;
        max-height: 60vh;

        & #questionnaireName {
            grid-column: span 2;
            margin: 1rem 0 -0.5rem;
            font-weight: 500;

            &::placeholder {
                color: #aaa;
            }
        }
    }

    & #addQuestionnaireButtons {
        display: flex;

        & button {
            flex: 1;
            border-radius: unset;
            border: unset;
            box-shadow: unset;
            margin: unset;
            padding: 1rem;
            font-size: 1.1rem;
            border-top: $borderLight;

            &:first-child {
                border-left: $borderLight;
                background-color: $swatchB;

                &:hover {
                    background-color: $hoverB;
                }
            }

            &:last-child {
                background-color: $swatchC;

                &:hover {
                    background-color: $hoverC;
                }
            }
        }
    }

    & label {
        width: 100%;
        margin: unset;
        & > div {
            display: flex;
            align-items: center;
        }

        & i {
            color: inherit;
            font-size: 1.2rem;
            margin: 0 0.5rem;
        }

        & > select {
            width: 100%;
            margin-right: -0.1rem;
        }
    }

    & #defineFrequency {
        position: relative;
    }

    & #defineTimes {
        display: flex;
        flex-direction: column;

        & select:not(:last-child) {
            margin-bottom: 0.5rem;
        }

        & #addTime {
            align-self: center;
            color: #777;
            margin: 0.5rem 0 -1rem;
            font-size: 24px;
            cursor: pointer;
        }
    }

    & #numberOfDays {
        position: absolute;
        bottom: -1.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.9rem;
        // margin: 0.2rem auto 0;

        & input {
            width: 2rem;
            text-align: center;
            font: inherit;
            box-shadow: unset;
            border: none;
            border-bottom: 1px solid #bbb;
            background-color: transparent;
            border-radius: unset;
            margin: 0 0.2rem;
            padding: 0;
            height: unset;
        }
    }

    & #endDateLabel {
        display: flex;
        justify-content: space-between;
    }
    & #noEndDate {
        color: #ccc;
        font-style: italic;
    }
}
</style>