
import { Questionnaire } from '@/ts/interfaces/Questionnaire';
import { defineComponent, PropType } from '@vue/runtime-core';

export default defineComponent({
    props: {
        questionnaire: { type: Object as PropType<Questionnaire>, required: true }
    },
    computed: {
        questions(){
            const { questions: allQuestions, collections } = this.$store.state.chooser!;
            const { questions, legends } = this.questionnaire;

            return questions.map(question => {
                if(typeof question !== 'string')
                    return allQuestions.find(q => q.id === question)?.text;
                
                switch(question[0]){
                    case 'l': return legends[parseInt(question.slice(1))];
                    case 'c': {
                        const collection = collections.find(c => c.id.toString() === question.slice(1)); 
                        return collection ? `שאלון: ${collection.name}` : undefined;
                    }
                }
            });
        }
    }
});
