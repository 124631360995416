<template>
    <tooltip :tooltipEl='questions.length ? `tooltip-${questionnaire.id}` : undefined' placement='bottom'>
        <template #default='slotProps'>
            <i class='material-icons-outlined questionsButton' v-bind='slotProps' :style='{ color: !questions.length && "#ccc" }'>
                contact_support
            </i>
        </template>
        <template #tooltip>
            טרם נבחרו שאלות לשאלון הזה
        </template>
    </tooltip>
    <teleport to='body' v-if='questions.length'>
        <div class='questionsTooltip' :id='`tooltip-${questionnaire.id}`'>
            <h3>תוכן השאלון</h3>
            <ul>
                <li v-for='question of questions' :key='question'>{{ $parse(question) }}</li>
            </ul>
        </div>
    </teleport>
</template>

<script lang='ts'>
import { Questionnaire } from '@/ts/interfaces/Questionnaire';
import { defineComponent, PropType } from '@vue/runtime-core';

export default defineComponent({
    props: {
        questionnaire: { type: Object as PropType<Questionnaire>, required: true }
    },
    computed: {
        questions(){
            const { questions: allQuestions, collections } = this.$store.state.chooser!;
            const { questions, legends } = this.questionnaire;

            return questions.map(question => {
                if(typeof question !== 'string')
                    return allQuestions.find(q => q.id === question)?.text;
                
                switch(question[0]){
                    case 'l': return legends[parseInt(question.slice(1))];
                    case 'c': {
                        const collection = collections.find(c => c.id.toString() === question.slice(1)); 
                        return collection ? `שאלון: ${collection.name}` : undefined;
                    }
                }
            });
        }
    }
});
</script>

<style lang="scss">
.questionsTooltip {
    background-color: $swatchC;
    box-shadow: 0.25rem 0.25rem 0.5rem #ccc;
    padding: 0.5rem;
    min-width: 10rem;
    width: 20rem;
    border: 1px solid #ccc;
    border-radius: $corner;
    position: absolute;

	display: none;
	z-index: 1000;
    opacity: 0;

	&[data-show] {
        opacity: 0;
        display: flex;
        flex-direction: column;
        animation: fade-in ease-in .1s forwards;
	}

    & h3 {
        text-align: right;
        padding-right: 0.1rem;
        font-weight: bold;
        font-size: 1rem;
    }

    & ul {
        list-style: circle;
        margin-right: 1rem;
    }

    & li {
        margin-top: 0.25rem;
        padding-right: 0.2rem;
    }
}
</style>