<template>
    <div id='patientQuestionnaires' v-if='!this.loading'>
        <div id='questionnaireButtons' v-if='questionnaires.length'>
            <router-link id='addQuestionnaireButton' to='questionnaires/data'>
                <i class='material-icons-round viewData'>insert_chart_outlined</i>
                <div>צפייה בנתונים</div>
            </router-link>
            <button type='button' id='addQuestionnaireButton' @click='dialogConfig = { mode: "new" }'>
                <i class='material-icons-round'>add</i>
                <div>הוספת שאלון</div>
            </button>
        </div>
        <div v-else id='noQuestionnaires'>
            <h1>אין פה שאלונים עדיין</h1>
            {{ $parse('לחץ', 'לחצי') }} על הקופסה כדי ליצור שאלון ראשון
            <img src='../../../assets/no-questionnaires.svg' @click='dialogConfig = { mode: "new" }' />
        </div>
        <questionnaire-dialog v-if='dialogConfig' v-bind='dialogConfig' @close='dialogConfig = null' />
        <div
            v-for='questionnaire of questionnaires'
            :key='questionnaire.id'
            class='patientQuestionnaireCard card'
            @click='onCardClick($event, questionnaire.id)'
        >
            <div class='questionnaireName'>{{ questionnaire.name }}</div>
            <div>{{ getFrequency(questionnaire) }}</div>
            <template v-if='questionnaire.times'>
                <template v-if='isEqual(questionnaire.end_date, questionnaire.start_date)'>
                    <div>בתאריך {{ formatDate(questionnaire.start_date) }}</div>
                    <div v-if='questionnaire.times.length'>בשעה {{ questionnaire.times[0] }}:00</div>
                    <div v-else>ניתן למילוי בכל שעה</div>
                </template>
                <template v-else>
                    <div v-if='questionnaire.times.length === 1'>בשעה {{ questionnaire.times[0] }}:00</div>
                    <div v-else-if='questionnaire.times.length'>{{ getTimes(questionnaire) }}</div>
                    <div v-else>ניתן למילוי בכל שעה</div>
                    <div>מילוי הבא בתאריך {{ formatDate(questionnaire.fill_next_on) }}</div>
                </template>
            </template>
            <div class='questionnaireToolbar'>
                <i class='material-icons-round editText'>edit</i>
                <questions-button :questionnaire='questionnaire' />
                <div class='endQuestionnaire' @click='dialogConfig = { questionnaireId: questionnaire.id, mode: "terminate" }'>
                    <i class='material-icons-outlined'>assignment</i>
                    <i class='material-icons-outlined'>block</i>
                </div>     
            </div>
        </div>
    </div>
    <div id='questionnairesLoading' v-else>
        <loading-indicator />
    </div>
</template>

<script lang='ts'>
import QuestionnaireDialog from './QuestionnaireDialog.vue';
import QuestionsButton from './QuestionsButton.vue';
import { format, isEqual } from 'date-fns';
import { defineComponent } from '@vue/runtime-core';
import { Questionnaire, RawQuestionnaire } from '@/ts/interfaces/Questionnaire';
import { setQuestionnaires } from '@/SharedChart/Patient/module';
import { ResponseObj } from '@/ts/Request';

const numToHeb = ['אפס', 'אחת', 'שתי', 'שלוש', 'ארבע', 'חמש', 'שש', 'שבע', 'שמונה', 'תשע','עשר','אחת-עשרה'];

type data = { dialogConfig: { questionnaireId: string, mode: 'modify' | 'terminate' | 'new '} | null, loading: boolean }

export default defineComponent({
    async beforeMount(){
        const response: ResponseObj<{ questionnaires: RawQuestionnaire[] }> = await this.$request('/patient/questionnaires');
        setQuestionnaires(response.body.questionnaires);
        this.loading = false;
    },
    data: (): data => ({ dialogConfig: null, loading: true }),
    components: { QuestionnaireDialog, QuestionsButton },
    computed: {
        questionnaires(){
            return this.$store.state.patient!.questionnaires
        }
    },
    methods: {
        getFrequency({ frequency, times, start_date, end_date }: Questionnaire){
            if(end_date && isEqual(start_date, end_date))
                return 'חד פעמי';
            else if(times.length > 1)
                return times.length === 2 ? `פעמיים ביום` : `${numToHeb[times.length]} פעמים ביום`;
            else if(frequency === 1)
                return 'פעם אחת ביום';
            else if(frequency > 1)
                return `פעם ב${frequency} ימים`;
        },
        testIfOnceEveryHour(times: (string | number)[]){
            const onceEveryHourBetween: typeof times = [];
            if(times.length < 5) return onceEveryHourBetween;

            for(let i = 0; i < times.length; i++){
                if(!i){
                    onceEveryHourBetween.push(times[i]);
                    continue;
                }
                if(times[i] as number - (times[i - 1] as number) > 1) break;
                if(i === times.length - 1) onceEveryHourBetween.push(times[i]);
            }

            return onceEveryHourBetween;
        },
        getTimes({ times }: { times: (string | number)[] }){
            const onceEveryHourBetween = this.testIfOnceEveryHour(times);
            if(onceEveryHourBetween.length === 2)
                return `פעם בשעה בין ${onceEveryHourBetween[0]}:00 ל-${onceEveryHourBetween[1]}:00`
            
            times = times.map(time => time + ':00');
            const last = times.pop();
            return `בשעות ${times.join(', ')} ו-${last}`;
        },
        formatDate(date: Date){
            return date ? format(date, 'dd/MM/yyyy') : null;
        },
        isEqual,
        onCardClick(event: Event, id: string){
            const target = event.target as HTMLElement;
            const { patientId } = this.$route.params;
            if(target.closest('.questionsButton'))
                this.$router.push(`/${patientId}/questionnaires/questions/${id}`);
            else if(target.closest('.editText'))
                this.dialogConfig = { questionnaireId: id, mode: 'modify' };
        }
    }
});
</script>

<style lang='scss'>
#questionnairesLoading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center ;
}
#questionnaireButtons {
    grid-column: span 3;
    display: flex;
    justify-content: space-between;

    & > * {
        display: flex;
        align-items: center;
        background-color: $swatchA;
        border-radius: 100px;
        font-size: 1.1rem;
        padding: 0.4rem 0.8rem 0.4rem 1.3rem;
        color: inherit;

        &:hover {
            box-shadow: .25rem .2rem .5rem #bbb;
        }

        & i {
            font-size: inherit;
            margin-left: 0.4rem;
            cursor: inherit;
        }

        & .viewData {
            margin-left: 0.6rem;
        }
    }
}

#patientQuestionnaires {
    display: grid;
    grid-template-columns: repeat(3, 18rem);
    grid-gap: 2rem 4rem;
    height: fit-content;
    margin-top: 2rem;
    position: relative;

    & #noQuestionnaires {
        grid-column: span 3;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1.3rem;

        & h1 {
            background-color: unset;
            font-size: 1.7rem;
            font-weight: 500;
        }

        & img {
            margin-top: 1.5rem;
            cursor: pointer;

            &:hover {
                filter: brightness(103%);
            }
        }
    }
}
.patientQuestionnaireCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 1rem 0;
    box-shadow: 4px 4px 8px -4px #aaa;
    position: relative;

    & .questionnaireToolbar {
        width: 2.5rem;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-around;
        padding: 0.7rem 0;

        & .endQuestionnaire {
            height: 24px;
        }
    }

    &:hover {
        box-shadow: 4px 4px 10px -3px #aaa;
    }

    & .questionnaireName {
        margin-bottom: 1rem;
        font-weight: 600;
    }

    & .questionsButton, & .editText, & .endQuestionnaire, & .viewResults {
        color: hsl(176, 41%, 70%);
        user-select: none;
        cursor: pointer;

        &:hover {
            transform: scale(1.1);
        }
    }

    & .endQuestionnaire {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        & > :last-child {
            position: absolute;
            font-size: 18px;
        }
    }

}
</style>