
import { isEqual, isBefore } from 'date-fns';
import { getCommitId } from '../helpers';
import { dateToDashDelimited } from '@/helpers';
import { defineComponent, PropType } from '@vue/runtime-core';
import { addCommit, setQuestionnaire } from '@/SharedChart/Patient/module';
import { RawQuestionnaire } from '@/ts/interfaces/Questionnaire';

const data = () => ({
    name: { value: '', error: false },
    frequency: {
        value: 1,
        days: null,
        options: {
            1: `פעם ביום`,
            [-2]: `פחות מפעם ביום`,
            0: `יותר מפעם ביום`,
            [-1]: `חד פעמי`,
        }
    } as { value: number, days: number | null, options: any },
    times: {
        value: [-1],
        options: { [-1]: 'לא משנה', ...Object.fromEntries(new Array(24).fill(true).map((_, index) => [index, (index < 10 ? `0${index}` : index) + ':00'])) },
        error: false
    } as { value: (number | null)[], options: any, error: boolean },
    startDate: { value: new Date() },
    endDate: { active: false, value: new Date() },
});

export default defineComponent({
    beforeMount(){
        if(this.mode === 'new')
            return

        if(this.mode === 'terminate')
            this.endDate.active = true;

        const questionnaire = this.$store.state.patient!.questionnaires.find(q => q.id === this.questionnaireId);
        if(!questionnaire)
            return;

        const { times, name, end_date, start_date, frequency } = questionnaire;
        if(times.length)
            this.times.value = [...times];
        if(end_date)
            this.endDate = { active: true, value: end_date };

        this.name.value = name;
        this.startDate.value = start_date;

        this.frequency.value = (() => {
            if(frequency > 1){
                this.frequency.days = frequency;
                return -2;
            }

            if(times.length > 1)
                return 0;

            return !end_date || !isEqual(start_date, end_date) ? 1 : -1;
        })();
    },
    data,
    props: {
        questionnaireId: String,
        mode: { type: String as PropType<'new' | 'modify' | 'terminate'> }
    },
    emits: ['close'],
    watch: {
        frequency: {
            handler({ value }){
                const firstTime = this.times.value[0] as number;
                if(value == 0 && this.times.value.length === 1)
                    this.times.value = [firstTime >= 0 ? firstTime : null, null];
                else if(value != 0 && this.times.value.length > 1)
                    this.times.value = [firstTime !== null ? firstTime : -1];
            },
            deep: true
        }
    },
    computed: {
        endDateMin(){
            return dateToDashDelimited(this.startDate.value as Date);
        }
    },
    methods: {
        onStartDateChange(){
            if(isBefore(this.endDate.value as Date, this.startDate.value as Date))
                this.endDate.value = this.startDate.value;
        },
        onTimeChange(){
            if(!this.times.value.includes(null))
                this.times.error = false;
        },
        validate(){
            const { name, frequency, times } = this;
            let hasErrors = false;
            if(!name.value.length)
                hasErrors = name.error = true;
            if(frequency.value == 0 && times.value.includes(null))
                hasErrors = times.error = true;

            return !hasErrors;
        },
        async submit(){
            if(!this.validate())
                return;

            try{
                const target = `/questionnaire${this.mode !== 'new' ? `/${this.questionnaireId}` : ''}`
                const newQuestionnaire = await this.$request(target, 'put', this.getPayload());
                if(this.mode !== 'new') this.$emit('close');
                else this.$router.push(`/${this.$route.params.patientId}/questionnaires/questions/${newQuestionnaire.body.id}`);
                
                setQuestionnaire(newQuestionnaire.body);
                addCommit(newQuestionnaire.body.commit_id);
            }catch(error){
                console.error(error)
            }
        },
        getPayload(){
            const { name, frequency, times, mode } = this;

            const startDate = dateToDashDelimited(this.startDate.value as Date);
            const endDate = dateToDashDelimited(this.endDate.value as Date);
            
            const payload = {} as RawQuestionnaire;
            if(mode !== 'terminate')
                Object.assign(payload, {
                    name: name.value,
                    frequency: frequency.value == -2 ? frequency.days : 1,
                    times: frequency.value == 0 || ![null, -1].includes(times.value[0]) ? times.value : []
                })
             else
                Object.assign(payload, { end_date: endDate })

            if(mode === 'new')
                Object.assign(payload, {
                    end_date: this.endDate.active ? endDate : (frequency.value === -1 ? startDate : null),
                    start_date: startDate
                })
            
            if(this.questionnaireId){
                const commitId = getCommitId(this.questionnaireId);
                if(commitId)
                    payload.commit_id = commitId;
            }

            return payload;
        }
    }
});
