
import QuestionnaireDialog from './QuestionnaireDialog.vue';
import QuestionsButton from './QuestionsButton.vue';
import { format, isEqual } from 'date-fns';
import { defineComponent } from '@vue/runtime-core';
import { Questionnaire, RawQuestionnaire } from '@/ts/interfaces/Questionnaire';
import { setQuestionnaires } from '@/SharedChart/Patient/module';
import { ResponseObj } from '@/ts/Request';

const numToHeb = ['אפס', 'אחת', 'שתי', 'שלוש', 'ארבע', 'חמש', 'שש', 'שבע', 'שמונה', 'תשע','עשר','אחת-עשרה'];

type data = { dialogConfig: { questionnaireId: string, mode: 'modify' | 'terminate' | 'new '} | null, loading: boolean }

export default defineComponent({
    async beforeMount(){
        const response: ResponseObj<{ questionnaires: RawQuestionnaire[] }> = await this.$request('/patient/questionnaires');
        setQuestionnaires(response.body.questionnaires);
        this.loading = false;
    },
    data: (): data => ({ dialogConfig: null, loading: true }),
    components: { QuestionnaireDialog, QuestionsButton },
    computed: {
        questionnaires(){
            return this.$store.state.patient!.questionnaires
        }
    },
    methods: {
        getFrequency({ frequency, times, start_date, end_date }: Questionnaire){
            if(end_date && isEqual(start_date, end_date))
                return 'חד פעמי';
            else if(times.length > 1)
                return times.length === 2 ? `פעמיים ביום` : `${numToHeb[times.length]} פעמים ביום`;
            else if(frequency === 1)
                return 'פעם אחת ביום';
            else if(frequency > 1)
                return `פעם ב${frequency} ימים`;
        },
        testIfOnceEveryHour(times: (string | number)[]){
            const onceEveryHourBetween: typeof times = [];
            if(times.length < 5) return onceEveryHourBetween;

            for(let i = 0; i < times.length; i++){
                if(!i){
                    onceEveryHourBetween.push(times[i]);
                    continue;
                }
                if(times[i] as number - (times[i - 1] as number) > 1) break;
                if(i === times.length - 1) onceEveryHourBetween.push(times[i]);
            }

            return onceEveryHourBetween;
        },
        getTimes({ times }: { times: (string | number)[] }){
            const onceEveryHourBetween = this.testIfOnceEveryHour(times);
            if(onceEveryHourBetween.length === 2)
                return `פעם בשעה בין ${onceEveryHourBetween[0]}:00 ל-${onceEveryHourBetween[1]}:00`
            
            times = times.map(time => time + ':00');
            const last = times.pop();
            return `בשעות ${times.join(', ')} ו-${last}`;
        },
        formatDate(date: Date){
            return date ? format(date, 'dd/MM/yyyy') : null;
        },
        isEqual,
        onCardClick(event: Event, id: string){
            const target = event.target as HTMLElement;
            const { patientId } = this.$route.params;
            if(target.closest('.questionsButton'))
                this.$router.push(`/${patientId}/questionnaires/questions/${id}`);
            else if(target.closest('.editText'))
                this.dialogConfig = { questionnaireId: id, mode: 'modify' };
        }
    }
});
